import { Info, Window } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "@repo/i18n-config";
import { isDesktop, isIOS, isMacOs } from "react-device-detect";

export const EmojiInfo = () => {
  const { t } = useTranslation("ui");

  if (isDesktop)
    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <Info sx={{ height: 20 }} />
        <Typography variant="body2" sx={{ mt: -0.125 }}>
          {t("EmojiInfo.part1")}{" "}
          {isIOS || isMacOs ? (
            t("EmojiInfo.macShortcut")
          ) : (
            <>
              <Window
                sx={{ height: 16, width: 16, position: "relative", top: 3 }}
              />{" "}
              + .{" "}
            </>
          )}
          {t("EmojiInfo.part2")}
        </Typography>
      </Box>
    );
};
