"use client";

import { Box, FormGroup, Typography } from "@mui/material";
import {
  Button,
  Card,
  EmojiInfo,
  ErrorMessage,
  FunctionNotAvailable,
  TextInput,
} from "@repo/ui";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import {
  cmsRoutes,
  mailNotificationContentValidator,
  notificationTitleValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useMe } from "@repo/api-config";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getFlags,
  sendMailNotification,
} from "@repo/api-config/services/company";
import { AxiosError } from "axios";
import {
  HttpValidationProblemDetails,
  SendEmailToCompanySubscribersCommand,
  UserInfoResponse,
} from "@repo/types/companyApi.types";
import { useTranslation } from "@repo/i18n-config";
import { useNavigate } from "@tanstack/react-router";
import { Info } from "@mui/icons-material";

const NewMailNotification = () => {
  const { t } = useTranslation("cms");

  const { tenantIdentifier } = useMe("company");
  const { showNotification } = useNotificationsContext();
  const { formatErrorMessage } = useServerErrorFormatter();
  const navigate = useNavigate();

  const { data: flags } = useQuery({
    queryKey: ["flags"],
    queryFn: () => getFlags(tenantIdentifier!),
    refetchOnMount: true,
  });

  const {
    mutate: sendMail,
    error,
    reset,
  } = useMutation<
    UserInfoResponse,
    AxiosError,
    SendEmailToCompanySubscribersCommand
  >({
    mutationFn: (values) => {
      reset();
      return sendMailNotification(tenantIdentifier || "", values);
    },
    onSuccess: () => {
      showNotification(t("MailNotifications.sent"));
      navigate({ to: cmsRoutes.mailNotifications.base });
    },
  });

  const mailNotificationForm = useForm({
    defaultValues: {
      title: "",
      content: "",
    },
    onSubmit: async ({ value }) => {
      sendMail({
        body: value.content,
        title: value.title,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  if (!flags?.emailToSubscribersLimit) return <FunctionNotAvailable />;

  return (
    <>
      <Typography variant="h6">
        {t("MailNotifications.yourEmails")}: {flags.emailToSubscribersUsed}/
        {flags.emailToSubscribersLimit}
      </Typography>
      <Card
        title={t("MailNotifications.newNotification")}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          "& h6": {
            width: { xs: "100%", md: "40%" },
          },
          mt: 2,
        }}
      >
        <Box
          component="form"
          sx={{ display: "flex", flex: 1 }}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            mailNotificationForm.handleSubmit();
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <FormGroup sx={{ gap: 2 }}>
              <TextInput
                form={mailNotificationForm}
                name="title"
                validators={{
                  onChange: notificationTitleValidator,
                }}
                label={t("MailNotifications.form.subject")}
                required
                charLimit={40}
              />
              <TextInput
                form={mailNotificationForm}
                name="content"
                validators={{
                  onChange: mailNotificationContentValidator,
                }}
                label={t("MailNotifications.form.content")}
                required
                multiline
                charLimit={1000}
              />
              <EmojiInfo />
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                <Info sx={{ height: 20 }} />
                <Typography variant="body2">
                  {t("MailNotifications.form.i18nInfo")}
                </Typography>
              </Box>
            </FormGroup>
            {error && (
              <ErrorMessage
                errorMessage={formatErrorMessage(
                  error as AxiosError<HttpValidationProblemDetails>
                )}
              />
            )}
            <mailNotificationForm.Subscribe
              selector={(state) => state.isValid && !state.isPristine}
              children={(isValid) => (
                <Button
                  type="submit"
                  sx={{ mt: 9, alignSelf: "flex-end" }}
                  variant="contained"
                  disabled={
                    !isValid ||
                    flags.emailToSubscribersUsed >=
                      flags.emailToSubscribersLimit
                  }
                >
                  {t("MailNotifications.form.submit")}
                </Button>
              )}
            />
          </Box>
        </Box>
      </Card>
    </>
  );
};
export default NewMailNotification;
